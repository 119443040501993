// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("/home/jenkins/workspace/starter-internationalized_master/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-1-js": () => import("/home/jenkins/workspace/starter-internationalized_master/src/pages/page1.js" /* webpackChunkName: "component---src-pages-page-1-js" */),
  "component---src-pages-subpage-page-1-js": () => import("/home/jenkins/workspace/starter-internationalized_master/src/pages/subpage/page1.js" /* webpackChunkName: "component---src-pages-subpage-page-1-js" */),
  "component---src-pages-subpage-subsubpage-page-1-js": () => import("/home/jenkins/workspace/starter-internationalized_master/src/pages/subpage/subsubpage/page1.js" /* webpackChunkName: "component---src-pages-subpage-subsubpage-page-1-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/jenkins/workspace/starter-internationalized_master/.cache/data.json")

